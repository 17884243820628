import api from "@/config/api.config";
import { Business } from "@/types/business.type";
import { Order } from "@/types/order.type";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import { User, UserAddress } from "@/types/user.type";

type PostOrderProps = {
  business_id: number;
  user_id: number;
  description?: string;
  items: ShoppingCartItem[];
  user: User;
  business: Business;
  payment_method: {
    name: string;
    flag: string;
  };
  address: Pick<
    UserAddress,
    "street" | "number" | "district" | "city" | "state" | "postal_code" | "info"
  >;
  change_amount?: number | null;
};

export async function postOrder({
  business_id,
  user_id,
  description,
  items,
  payment_method,
  user,
  business,
  address,
  change_amount,
}: PostOrderProps) {
  const { data: order } = await api.post("/order", {
    type: "automatic",
    business_id,
    user_id,
    description,
    status: "pending",
    metadata: {
      items,
      user: {
        name: user.name,
        phone_number: user.phone_number,
        address: address,
      },
      business: {
        id: business.id,
        name: business.name,
        phone_number: business.phone_number,
      },
      payment_method,
      change_amount,
    },
  });

  return order as Order;
}
