import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Order } from "@/types/order.type";
import formatBusinessPhoneNumber from "@/utils/formatBusinessPhoneNumber.util";
import { Dispatch, SetStateAction } from "react";

interface CancelOrderAlertProps {
  order: Order;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CancelOrderAlert({
  order,
  open,
  setOpen,
}: CancelOrderAlertProps) {
  if (!order || !order.metadata || !order.metadata.business) {
    return null;
  }

  const { id, metadata } = order;
  const { name, phone_number } = metadata.business;

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
          <AlertDialogDescription>
            Para cancelar um pedido é necessário entrar em contato com o
            restaurante responsável.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="my-4 space-y-2">
          <p className="text-sm font-medium">Detalhes do pedido:</p>
          <ul className="list-inside list-disc space-y-1 text-sm text-label-primary">
            <li>
              Número do pedido: #<span className="font-semibold">{id}</span>
            </li>
            <li>
              Nome do restaurante: <span className="font-semibold">{name}</span>
            </li>
            <li>
              Número de telefone:{" "}
              <span className="font-semibold">
                {formatBusinessPhoneNumber(phone_number)}
              </span>
            </li>
          </ul>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button variant="outline">Voltar</Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
