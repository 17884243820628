import { Badge } from "@/components/ui/badge";
import { DAYS } from "@/data/days";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import { cn } from "@/lib/utils";
import { Business } from "@/types/business.type";
import formatBusinessAddress from "@/utils/formatBusinessAddress.util";
import formatBusinessPhoneNumber from "@/utils/formatBusinessPhoneNumber.util";
import isBusinessInOperationTime from "@/utils/isBusinessInOperationTime.util";
import {
  IconBuildingStore,
  IconMapPin,
  IconMoon,
  IconPhone,
} from "@tabler/icons-react";

type BusinessSheetProps = {
  business: Business;
};

export default function BusinessSheet({ business }: BusinessSheetProps) {
  const isBusinessOpenNow = isBusinessInOperationTime(business);

  const paymentMethods = business.metadata.paymentMethods || [];

  return (
    <div className="flex w-full flex-col items-center justify-start gap-6 h-full overflow-auto">
      <div className="flex w-full flex-col gap-4">
        <div className="flex items-center justify-start gap-2 text-label-primary flex-wrap">
          <IconBuildingStore size={24} />
          <h1 className="text-xl font-semibold text-start text-label-primary loine">
            {business.name}
          </h1>
        </div>
        <div className="w-full flex flex-col items-start justify-center gap-2">
          <div className="w-full ">
            <div className="flex items-center justify-start gap-2 text-label-primary">
              <IconPhone size={16} />
              <h1 className="font-semibold text-base">Número de telefone</h1>
            </div>

            {business.phone_number && (
              <p className="text-base">
                {formatBusinessPhoneNumber(business.phone_number)}
              </p>
            )}
          </div>
          <div className="w-full">
            <div className="flex items-center justify-start gap-2 text-label-primary">
              <IconMapPin size={16} />
              <h1 className="font-semibold text-base">Endereço</h1>
            </div>
            <p className="text-base">
              {formatBusinessAddress(business.address)}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-start gap-2 border-b pb-2">
          <h1 className="text-label-primary font-bold">
            Horários de funcionamento
          </h1>
          <Badge
            className={cn(" text-black", {
              "bg-green-200 hover:bg-green-300": isBusinessOpenNow,
              "bg-red-500 hoverbg-red-600 text-white": !isBusinessOpenNow,
            })}
          >
            {isBusinessOpenNow ? "Aberto" : "Fechado"}
          </Badge>
        </div>
        <div className="flex items-start justify-center gap-2 flex-col">
          {business.metadata.hours.map((d) => {
            if (!d.is_open)
              return (
                <div className="flex items-center justify-center gap-2">
                  <p key={d.day_of_week}>
                    {DAYS[Number(d.day_of_week) - 1]}: Fechado
                  </p>
                  <IconMoon stroke={1} size={16} />
                </div>
              );

            return (
              <p key={d.day_of_week}>
                {DAYS[Number(d.day_of_week) - 1]}: {d.start_time} às{" "}
                {d.end_time}
              </p>
            );
          })}
        </div>
      </div>
      {paymentMethods.length > 0 && (
        <div className="w-full flex flex-col gap-4">
          <div className="flex items-center justify-start gap-2 border-b pb-2">
            <h1 className="text-label-primary font-bold">
              Pagamento na entrega
            </h1>
          </div>
          <div className="flex items-start justify-center gap-4 flex-col">
            {paymentMethods.map((method, i) => (
              <div
                className="flex items-center justify-start gap-2 border-b w-full pb-2"
                key={i}
              >
                <div className="w-6 h-6 flex items-center justify-center">
                  <img
                    src={
                      PAYMENT_FLAGS[method.flag as keyof typeof PAYMENT_FLAGS]
                        .image
                    }
                    alt={method.name}
                  />
                </div>
                <p className="text-label-primary">{method.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
