/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from "react";
import { Input } from "@/components/ui/input";
import { ChevronLeft } from "lucide-react";
import debounce from "lodash/debounce";
import { GoogleSearchAddress } from "@/types/user.type";
import GoogleMapsAddresses, {
  GoogleSearchAddresses,
} from "./googleMapsAddresses";
import UserAddresses from "./user-addresses";
import { AddAddressDrawer } from "./addAddress.drawer";
import Spinner from "@/components/ui/spinner";
import {
  getGoogleMapsAddress,
  getGoogleMapsSearch,
} from "../queries/address.queries";

export default function Addresses() {
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedAddress, setSelectedAddress] =
    useState<GoogleSearchAddress | null>(null);
  const [addAddressOpen, setAddAddressOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<GoogleSearchAddresses[]>(
    []
  );
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(
    null
  );
  const [isFetchingSelectedAddress, setIsFetchingSelectedAddress] =
    useState(false);

  const handleReset = () => {
    setSearchInput("");
    setIsSearching(false);
    setSearchResults([]);
  };

  const handleSearch = useCallback(
    debounce(async (search: string) => {
      if (!search) return setSearchResults([]);

      try {
        setIsFetching(true);
        const data = await getGoogleMapsSearch(search);

        setSearchResults(data);
      } catch (error) {
        console.error("Erro ao buscar endereços no backend", error);
        setSearchResults([]);
      } finally {
        setIsFetching(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleSearch(searchInput);
    setIsSearching(!!searchInput);
  }, [searchInput, handleSearch]);

  const handleSelectAddress = async (result: GoogleSearchAddresses) => {
    try {
      setIsFetchingSelectedAddress(true);
      setSelectedAddressId(result.place_id);

      const data = await getGoogleMapsAddress(result.place_id);

      const addressComponents = data.address_components;

      const address: GoogleSearchAddress = {
        city:
          addressComponents.find((c: any) =>
            c.types.includes("administrative_area_level_2")
          )?.long_name || "",
        street:
          addressComponents.find((c: any) => c.types.includes("route"))
            ?.long_name || "",
        state:
          addressComponents.find((c: any) =>
            c.types.includes("administrative_area_level_1")
          )?.long_name || "",
        district:
          addressComponents.find((c: any) => c.types.includes("sublocality"))
            ?.long_name || "",
        postal_code:
          addressComponents.find((c: any) => c.types.includes("postal_code"))
            ?.long_name || "",
        number:
          addressComponents.find((c: any) => c.types.includes("street_number"))
            ?.long_name || "",
        formatted_address: data.formatted_address,
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lng,
      };

      setAddAddressOpen(true);
      setSelectedAddress(address);
    } catch (error) {
      setAddAddressOpen(false);
      setSelectedAddress(null);
      setSelectedAddressId(null);
      console.error("Failed to fetch place details", error);
    } finally {
      setIsFetchingSelectedAddress(false);
    }
  };

  useEffect(() => {
    if (!addAddressOpen) {
      setSelectedAddress(null);
      setSelectedAddressId(null);
    }
  }, [addAddressOpen]);

  return (
    <>
      <div className="flex flex-col gap-4 h-full flex-1">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl font-semibold">Endereços</h1>
        </div>
        <div className="w-full flex flex-col gap-6 items-start justify-start">
          {isSearching && (
            <button
              onClick={handleReset}
              className="flex items-center gap-2 text-label-primary"
            >
              <ChevronLeft className="h-5 w-5" />
              <span>Voltar para meus endereços</span>
            </button>
          )}
          <Input
            placeholder="Buscar ou adicionar novo endereço"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="flex-grow"
          />
          {isSearching && isFetching && (
            <div className="flex items-center justify-center gap-2">
              <Spinner />
              <p>Procurando...</p>
            </div>
          )}
          {!isSearching && <UserAddresses />}
          {isSearching && !isFetching && (
            <GoogleMapsAddresses
              addresses={searchResults}
              handleSelectAddress={handleSelectAddress}
              selectedAddressId={selectedAddressId}
              isFetchingSelectedAddress={isFetchingSelectedAddress}
            />
          )}
        </div>
      </div>

      {selectedAddress && (
        <AddAddressDrawer
          address={selectedAddress}
          open={addAddressOpen}
          setOpen={setAddAddressOpen}
          setSearchInput={setSearchInput}
        />
      )}
    </>
  );
}
