import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Order } from "@/types/order.type";
import { formatToNaturalLanguage } from "@/utils/formatToNaturalLanguage.util";
import NoImageFallback from "@/components/fallbacks/no-image.fallback";
import { typesTraductions } from "@/data/typesTraductions";
import { cn } from "@/lib/utils";

export default function OrderItem({
  order,
  onClickDetails,
  onClickOrderAgain,
}: {
  order: Order;
  onClickDetails: (order: Order) => void;
  onClickOrderAgain?: (order: Order) => void;
}) {
  return (
    <Card key={order.id} className="h-[unset]">
      <CardHeader className="flex flex-row items-center justify-start gap-6 w-full flex-wrap">
        <div>
          <NoImageFallback className="w-20 h-20" />
        </div>
        <div className="flex flex-col">
          <CardTitle className="text-base">Pedido #{order.id}</CardTitle>
          <CardDescription className="flex flex-col items-start justify-center gap-1">
            <span className="text-label-secondary text-base">
              {formatToNaturalLanguage(new Date(order.pending_at))}
            </span>
            <Badge
              className={cn("text-white text-[11px]  font-semibold", {
                "bg-gray-600": order.status === "pending",
                "bg-green-600": order.status === "done",
                "bg-red-600": order.status === "canceled",
                "bg-yellow-600": order.status === "production",
                "bg-blue-600": order.status === "delivering",
              })}
            >
              {typesTraductions[order.status]}
            </Badge>
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="gap-2 flex items-center justify-center flex-wrap">
        {onClickOrderAgain && (
          <Button
            className="bg-green-600 hover:bg-green-700 flex-1"
            onClick={() => onClickOrderAgain(order)}
          >
            Pedir novamente
          </Button>
        )}
        <Button
          className="bg-gray-600 hover:bg-gray-700 flex-1"
          onClick={() => onClickDetails(order)}
        >
          Detalhes
        </Button>
      </CardContent>
    </Card>
  );
}
