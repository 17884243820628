import { cn } from "@/lib/utils";

export default function NoImageFallback({ className }: { className?: string }) {
  return (
    <div>
      <img
        className={cn("w-full object-cover rounded-lg", className)}
        src="/fallbacks/no-image.png"
      />
    </div>
  );
}
