import NoImageFallback from "@/components/fallbacks/no-image.fallback";
import { Button } from "@/components/ui/button";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import getS3Image from "@/utils/getS3Image.util";
import { IconTrash } from "@tabler/icons-react";

type CartItemProps = {
  item: ShoppingCartItem;
};

export default function CartItem({ item }: CartItemProps) {
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);

  const itemQuantity = item.quantity;

  function onRemoveItemFromCart() {
    const newCart = {
      ...shoppingCart,
      items: shoppingCart.items.filter((i) => i.id !== item.id),
    };
    setShoppingCart(newCart);
  }

  function onDecrement() {
    if (itemQuantity === 1) {
      onRemoveItemFromCart();
    } else {
      const newCart = {
        ...shoppingCart,
        items: shoppingCart.items.map((i) =>
          i.id === item.id ? { ...i, quantity: i.quantity - 1 } : i
        ),
      };
      setShoppingCart(newCart);
    }
  }

  function onIncrement() {
    const newCart = {
      ...shoppingCart,
      items: shoppingCart.items.map((i) =>
        i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
      ),
    };
    setShoppingCart(newCart);
  }

  return (
    <div className="w-full min-h-28 rounded-md p-4 border flex flex-col items-center gap-4">
      <div className="w-full flex items-center justify-between flex-wrap gap-6">
        <div className="flex items-center justify-center gap-4">
          {item.logo_url ? (
            <img
              src={getS3Image(item.logo_url)}
              alt={item.title}
              className="min-w-24 min-h-24 max-w-24 max-h-24 rounded-lg object-cover"
            />
          ) : (
            <NoImageFallback className="w-20 h-20" />
          )}
          <div className="flex flex-col items-start justify-center">
            <p className="text-base text-label-primary font-semibold">
              {item.title}
            </p>
            <p className="text-base text-label-secondary">R$ {item.price}</p>
            <p className="text-base text-label-secondary">
              Quantidade: {itemQuantity}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4">
          <div className="flex items-center gap-2">
            {itemQuantity === 1 ? (
              <button onClick={onDecrement}>
                <IconTrash color="red" />
              </button>
            ) : (
              <Button
                onClick={onDecrement}
                className="w-8 h-8 rounded-full text-lg font-semibold bg-black hover:bg-black"
              >
                {itemQuantity === 1 ? <IconTrash color="red" /> : "-"}
              </Button>
            )}

            <span className="font-semibold text-lg">{itemQuantity}</span>
            <Button
              onClick={onIncrement}
              className="w-8 h-8 rounded-full text-lg font-semibold bg-black hover:bg-black"
            >
              +
            </Button>
          </div>
        </div>
      </div>
      {item.optionals && item.optionals.length > 0 && (
        <div className="w-full flex flex-col gap-2">
          {item.optionals.map((optional) => (
            <div
              key={optional.id}
              className="flex items-center justify-between"
            >
              <h1 className="text-base text-label-secondary">
                {optional.title}
              </h1>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
