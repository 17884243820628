import { Skeleton } from "@/components/ui/skeleton";
import { IconChevronRight } from "@tabler/icons-react";

export default function LoadingBusinessFallback() {
  return (
    <div className="w-full flex-1 flex items-start justify-center">
      <section className="w-full h-full py-6 flex-col gap-4 flex">
        <div className="w-full flex-col flex items-center justify-start flex-1">
          <header className="w-full bg-white shadow-sm border-b-2 rounded-lg pb-4 flex flex-col gap-6 items-start justify-start">
            <div className="w-full px-6 flex flex-col gap-4">
              <div className="flex items-center justify-between ">
                <Skeleton className="h-8 w-48" /> {/* Business name */}
                <IconChevronRight size={20} />{" "}
              </div>
              <div className="flex flex-col items-start gap-2 justify-between">
                <div className="flex items-center gap-2">
                  <Skeleton className="h-4 w-32" /> {/* Text placeholder */}
                </div>
                <div className="flex items-center gap-2">
                  <Skeleton className="h-4 w-48" /> {/* Text placeholder */}
                </div>
                <Skeleton className="h-6 w-20" /> {/* Badge placeholder */}
              </div>
            </div>
            <div className="w-full relative px-6 h-11">
              <Skeleton className="h-full w-full rounded-md" />{" "}
            </div>
          </header>

          <div className="w-full flex flex-col gap-6 pt-6">
            <div className="w-full flex-col gap-6 px-6 flex items-center justify-start flex-1">
              {[1, 2, 3].map(
                (
                  category // Assuming 3 categories for the skeleton
                ) => (
                  <div key={category} className="w-full flex flex-col gap-4">
                    <Skeleton className="h-8 w-48" />{" "}
                    {/* Category title placeholder */}
                    <div className="flex flex-col gap-4">
                      {[1, 2, 3].map(
                        (
                          item // Assuming 3 items per category for the skeleton
                        ) => (
                          <Skeleton key={item} className="h-24 w-full" /> // Menu item placeholder
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
