/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "@/context/auth.context";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

export const phoneSchema = z
  .string()
  .regex(/^55\d{10,11}$/, "Número de telefone inválido");

export const passwordSchema = z
  .string()
  .min(6, "A senha deve ter pelo menos 6 caracteres");

export const signInSchema = z.object({
  identifier: phoneSchema,
});

export const signUpSchema = z.object({
  name: z.string().min(1, "Nome é obrigatório"),
  phone_number: phoneSchema,
});

export type SignInSchema = z.infer<typeof signInSchema>;
export type SignUpSchema = z.infer<typeof signUpSchema>;

export function useSignInForm({ setTab }: { setTab: (value: string) => void }) {
  const navigate = useNavigate();

  const { signIn } = useAuth();

  const form = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
    mode: "onChange",
  });

  async function handleSignIn(data: SignInSchema) {
    try {
      await signIn(data);
    } catch (err: any) {
      if (err.response.status === 404) {
        setTab("sign-up");
        navigate(`?identifier=${encodeURIComponent(data.identifier)}`, {
          replace: true,
        });
      }
      console.error(err);
    }
  }

  return {
    form,
    handleSignIn,
  };
}

export function useSignUpForm() {
  const { signUp } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const identifier = queryParams.get("identifier");

  const form = useForm<SignUpSchema>({
    resolver: zodResolver(signUpSchema),
    mode: "onChange",
    defaultValues: {
      phone_number: identifier || "",
    },
  });

  async function handleSignUp(data: SignUpSchema) {
    try {
      await signUp(data);
    } catch (err: any) {
      if (
        err.response.data.message.includes(
          "duplicate key value violates unique constraint"
        )
      ) {
        toast.error(
          "Já existe um usuário cadastrado com esse número de telefone."
        );

        return form.setError("phone_number", {
          type: "manual",
          message:
            "Já existe um usuário cadastrado com esse número de telefone.",
        });
      }

      toast.error("Erro ao cadastrar, tente novamente.");
    }
  }

  return {
    form,
    handleSignUp,
  };
}
