import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useOrders from "../hook/order.hook";
import OrderTab from "../components/order-tab";
import { ViewOrderSheet } from "../components/view.order.sheet";
import LoadingOrdersFallback from "../components/fallbacks/loading-orders.fallback";

// TODO: Passar a salvar os dados do restaurante no metadata do pedido
// TODO: Componentizar o card de pedido

export default function OrdersPage() {
  const [open, setOpen] = useState(false);

  const { isLoading, pendingOrders, doneOrders, selectedOrder, onSelectOrder } =
    useOrders();

  if (isLoading) return <LoadingOrdersFallback />;

  return (
    <>
      <Tabs defaultValue="pending" className="w-full h-full">
        <div className="w-full   px-8 py-6 h-full flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Meus Pedidos</h1>
          </div>
          <div>
            <TabsList className="w-full xl:max-w-sm">
              <TabsTrigger value="pending" className="flex-1">
                Em andamento
              </TabsTrigger>
              <TabsTrigger value="done" className="flex-1">
                Finalizado
              </TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="pending">
            <OrderTab
              orders={pendingOrders}
              onClickDetails={(order) => {
                onSelectOrder(order);
                setOpen(true);
              }}
            />
          </TabsContent>
          <TabsContent value="done">
            <OrderTab
              orders={doneOrders}
              onClickDetails={(order) => {
                onSelectOrder(order);
                setOpen(true);
              }}
              onClickOrderAgain={() => {
                console.log("Pedir novamente");
              }}
            />
          </TabsContent>
        </div>
      </Tabs>

      {selectedOrder && (
        <ViewOrderSheet open={open} setOpen={setOpen} order={selectedOrder} />
      )}
    </>
  );
}
