import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import BusinessSheet from "./business.sheet";
import { IconChevronRight } from "@tabler/icons-react";
import { Business } from "@/types/business.type";

type BusinessSheetMobileProps = {
  business: Business;
};
export default function BusinessSheetMobile({
  business,
}: BusinessSheetMobileProps) {
  if (!business) return;

  return (
    <Sheet>
      <SheetTrigger asChild className="cursor-pointer">
        <IconChevronRight />
      </SheetTrigger>

      <SheetContent className="p-0 w-full overflow-auto scroll-area flex flex-col xl:max-w-xl">
        <div className="w-full flex-1 px-4 pb-4 flex items-center justify-start flex-col gap-4 border-l-2">
          <BusinessSheet business={business} />
        </div>
      </SheetContent>
    </Sheet>
  );
}
