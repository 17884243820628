import { Button } from "@/components/ui/button";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { useNavigate } from "react-router-dom";

export default function NotFoundCartItemsFallback() {
  const navigate = useNavigate();

  const business = useBusinessStore((state) => state.business);

  function handleRedirectToMenu() {
    if (business) {
      navigate(`/b/${business.id}`);
    } else {
      navigate("/");
    }
  }
  return (
    <div className="w-full  items-center justify-center flex flex-col gap-4 py-10 px-4">
      <h1 className="font-semibold text-2xl text-center">
        Seu carrinho está vazio
      </h1>
      <p className="text-center">
        Adicione produtos ao seu carrinho para poder finalizar a sua compra.
      </p>
      <Button
        onClick={handleRedirectToMenu}
        className="bg-green-600 hover:bg-green-700"
      >
        Adicionar produtos
      </Button>
    </div>
  );
}
