import api from "@/config/api.config";
import { useUserStore } from "@/pages/profile/store/user.store";
import { Order } from "@/types/order.type";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

export default function useOrders() {
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();

  const user = useUserStore((state) => state.user);

  const { data: orders = [], isLoading } = useQuery({
    queryKey: ["orders", user?.id],
    queryFn: async () => {
      if (!user) return [];

      const { data } = await api.get<Order[]>(`/order/user/${user.id}`);

      return data ?? [];
    },
    enabled: !!user,
  });

  function handleSelectOrder(order: Order) {
    setSelectedOrder(order);
  }

  function filterOrders(orders: Order[], status: "pending" | "done") {
    if (status === "pending") {
      return orders.filter(
        (order) =>
          order.status === "pending" ||
          order.status === "delivering" ||
          order.status === "production"
      );
    }

    return orders.filter(
      (order) => order.status === "done" || order.status === "canceled"
    );
  }

  function sortOrders(orders: Order[]) {
    return [...orders].sort((a, b) => {
      if (a.pending_at > b.pending_at) return -1;
      return 1;
    });
  }

  const pendingOrders = filterOrders(sortOrders(orders), "pending");
  const doneOrders = filterOrders(sortOrders(orders), "done");

  return {
    selectedOrder,
    pendingOrders,
    doneOrders,
    onSelectOrder: handleSelectOrder,
    isLoading,
  };
}
