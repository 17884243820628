export default function Header({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-1 p-6">
      <h1 className="text-center text-[17px] font-bold text-label-primary">
        {title}
      </h1>
      {description && (
        <p className="text-center text-label-secondary text-[13px] !mt-0">
          {description}
        </p>
      )}
    </div>
  );
}
