import { Business } from "@/types/business.type";
import { Menu, MenuCategory, MenuItem } from "@/types/menu.type";
import { ShoppingCart } from "@/types/shopping-cart.type";
import { create } from "zustand";

// TODO: Verificar se é necessário implementar uma store separada para carrinho
type BusinessStore = {
  isLoading: boolean;
  business: Business | undefined;
  menu: Menu | undefined;
  menuCategories: MenuCategory[];
  menuItems: MenuItem[];
  shoppingCart: ShoppingCart;

  setIsLoading: (value: boolean) => void;
  setBusiness: (business: Business | undefined) => void;
  setMenu: (menu: Menu | undefined) => void;
  setMenuCategories: (menuCategories: MenuCategory[]) => void;
  setMenuItems: (menuItems: MenuItem[]) => void;
  setShoppingCart: (ShoppingCart: ShoppingCart) => void;
};

export const useBusinessStore = create<BusinessStore>((set) => ({
  isLoading: false,
  business: undefined,
  menu: undefined,
  menuCategories: [],
  menuItems: [],
  shoppingCart: {
    items: [],
  },

  setIsLoading: (isLoading) =>
    set({
      isLoading,
    }),

  setBusiness: (business) =>
    set({
      business,
    }),

  setMenu: (menu) =>
    set({
      menu,
    }),

  setMenuCategories: (menuCategories) =>
    set({
      menuCategories,
    }),

  setMenuItems: (menuItems) =>
    set({
      menuItems,
    }),

  setShoppingCart: (shoppingCart) => set({ shoppingCart }),
}));
