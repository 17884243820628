import { Order } from "@/types/order.type";
import { Dispatch, SetStateAction, useState } from "react";
import { formatUserAddress } from "@/utils/formatUserAddress.util";
import { UserAddress } from "@/types/user.type";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { formatToNaturalLanguage } from "@/utils/formatToNaturalLanguage.util";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import { IconDots, IconExternalLink } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import formatBusinessPhoneNumber from "@/utils/formatBusinessPhoneNumber.util";
import getS3Image from "@/utils/getS3Image.util";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import CancelOrderAlert from "./cancel-order.alert";
import NoImageFallback from "@/components/fallbacks/no-image.fallback";

type OrderModalProps = {
  order: Order;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

// TODO: Componente de visualização de pedido
export function ViewOrderSheet({ order, open, setOpen }: OrderModalProps) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const navigate = useNavigate();
  const orderPrice = order.metadata.items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  function handleRedirectToBusinessPage() {
    navigate(`/b/${order.business_id}`);
  }

  const showCancelButton =
    order.status !== "canceled" && order.status !== "done";

  const showDropDown = showCancelButton;

  function handleCancelOrder() {
    setCancelOpen(true);
  }

  return (
    <>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent
          className="sm:max-w-[unset] w-full text-gray-800 p-0 scroll-area overflow-auto"
          classNameOverlay="hidden"
        >
          <div className="w-full flex flex-col items-start justify-center gap-6 p-4 pt-0">
            <div className="w-full flex items-center justify-between">
              <p className="text-label-secondary text-start">
                Pedido #{order.id} realizado em{" "}
                {formatToNaturalLanguage(order.pending_at)}
              </p>
              {showDropDown && (
                <DropdownMenu
                  open={dropDownOpen}
                  onOpenChange={(open) => {
                    setDropDownOpen(open);
                    setConfirmCancel(false);
                  }}
                >
                  <DropdownMenuTrigger className="p-2 rounded-lg border">
                    <IconDots />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {showCancelButton && (
                      <Button
                        className="w-full h-full bg-transparent hover:bg-transparent"
                        onClick={() => {
                          if (!confirmCancel) {
                            return setConfirmCancel(true);
                          }

                          return handleCancelOrder();
                        }}
                      >
                        {!confirmCancel && (
                          <p className="text-label-primary">Cancelar pedido</p>
                        )}
                        {confirmCancel && (
                          <p className="text-red-500">Confirmar</p>
                        )}
                      </Button>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
            <div className="w-full rounded-lg border flex flex-col">
              <div className="p-6 w-full flex  items-center justify-between border-b flex-wrap gap-6">
                <div className="flex items-center justify-center gap-4">
                  <NoImageFallback className="w-12 h-12" />
                  <div>
                    <div className="flex flex-col items-start justify-center">
                      <p className="text-label-primary font-semibold">
                        {order.metadata.business.name}
                      </p>
                      {order.metadata.business.phone_number && (
                        <p className="text-base text-label-secondary">
                          {formatBusinessPhoneNumber(
                            order.metadata.business.phone_number
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <IconExternalLink
                    className="cursor-pointer"
                    onClick={handleRedirectToBusinessPage}
                  />
                </div>
              </div>
              <div className="p-4 w-full flex flex-col items-start justify-center gap-4">
                <div className="flex flex-col items-start justify-start gap-1">
                  <h1 className="text-label-secondary text-base">
                    Endereço de entrega
                  </h1>
                  <p className="text-label-primary font-semibold text-base">
                    {formatUserAddress(
                      order.metadata.user.address as UserAddress
                    )}
                  </p>
                </div>
                {order.description !== "" && (
                  <div className="flex flex-col items-start justify-start gap-1">
                    <h1 className="text-label-secondary text-base">
                      Observações do pedido
                    </h1>
                    <p className="text-label-primary font-semibold text-base">
                      {order.description}
                    </p>
                  </div>
                )}
                <div className="flex flex-col items-start justify-start gap-1">
                  <h1 className="text-label-secondary text-base">
                    Forma de pagamento
                  </h1>
                  <div className="flex items-center justify-center gap-2">
                    {PAYMENT_FLAGS[
                      order.metadata.payment_method
                        .flag as keyof typeof PAYMENT_FLAGS
                    ].image && (
                      <div className="w-5 h-5 flex items-center justify-center">
                        <img
                          src={
                            PAYMENT_FLAGS[
                              order.metadata.payment_method
                                .flag as keyof typeof PAYMENT_FLAGS
                            ].image
                          }
                          alt={order.metadata.payment_method.flag}
                          className="w-5 h-5"
                        />
                      </div>
                    )}
                    <p className="text-label-primary font-semibold text-base">
                      {order.metadata.payment_method.name}
                    </p>
                  </div>
                </div>
                {order.metadata.payment_method.flag === "Dinheiro" && (
                  <div className="flex flex-col items-start justify-start gap-1">
                    <h1 className="text-label-secondary text-base">
                      Troco para
                    </h1>
                    <p className="text-label-primary font-semibold text-base">
                      {formatMoneyValue(order.metadata.change_amount || 0)}
                    </p>
                  </div>
                )}
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <h1 className="text-label-secondary text-base">
                    Itens do pedido
                  </h1>
                  <div className="w-full flex flex-col gap-2">
                    {order.metadata.items.map((item) => (
                      <div className="w-full rounded-md p-2 border flex flex-col sm:flex-row sm:items-center items-start justify-between flex-wrap gap-2">
                        <div className="w-full flex items-center justify-between flex-wrap gap-6">
                          <div className="flex flex-col items-start sm:flex-row justify-start gap-2 flex-wrap">
                            {item.logo_url ? (
                              <img
                                src={getS3Image(item.logo_url)}
                                alt={item.title}
                                className="min-w-14 min-h-14 max-w-14 max-h-14 rounded-lg object-cover"
                              />
                            ) : (
                              <NoImageFallback className="w-14 h-14" />
                            )}
                            <div className="flex flex-col items-start justify-center">
                              <p className=" text-label-primary font-semibold text-base">
                                {item.title}
                              </p>

                              <p className="text-base text-label-secondary">
                                Quantidade: {item.quantity}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className="text-base text-label-secondary">
                              R$ {item.price}
                            </p>
                          </div>
                        </div>
                        {item.optionals && item.optionals.length > 0 && (
                          <div className="w-full flex flex-col gap-2">
                            {item.optionals.map((optional) => (
                              <div
                                key={optional.id}
                                className="flex items-center justify-between"
                              >
                                <h1 className="text-base text-label-secondary">
                                  {optional.title}
                                </h1>
                                <p className="font-medium text-green-600 text-base">
                                  {optional.price === 0
                                    ? "Grátis"
                                    : `+ ${optional.price}`}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <h1 className="text-label-secondary text-base">
                    Resumo de valores
                  </h1>
                  <div className="w-full flex flex-col items-start justify-center gap-2">
                    <div className="w-full flex items-center justify-between gap-2">
                      <p className="text-label-primary text-base">Subtotal</p>
                      <span className="text-label-secondary text-base">
                        {formatMoneyValue(orderPrice)}
                      </span>
                    </div>
                    <div className="w-full flex items-center justify-between gap-2">
                      <p className="text-label-primary text-base">
                        Taxa de entrega
                      </p>
                      <span className=" text-base text-green-600 font-semibold">
                        Grátis
                      </span>
                    </div>
                    <div className="w-full flex items-center justify-between gap-2">
                      <p className="text-label-primary text-base">Total</p>
                      <span className="text-base text-green-600 font-semibold">
                        {formatMoneyValue(orderPrice)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>

      <CancelOrderAlert
        order={order}
        open={cancelOpen}
        setOpen={setCancelOpen}
      />
    </>
  );
}
