import { Business } from "@/types/business.type";
import isBusinessInOperationTime from "@/utils/isBusinessInOperationTime.util";
import { Dispatch, SetStateAction } from "react";
import BusinessSheetMobile from "./business-sheet/business.sheet.mobile";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { IconSearch } from "@tabler/icons-react";
import { Input } from "@/components/ui/input";

type BusinessHeaderProps = {
  business: Business;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};
export default function BusinessHeader({
  business,
  search,
  setSearch,
}: BusinessHeaderProps) {
  const isBusinessOpenNow = business
    ? isBusinessInOperationTime(business)
    : false;

  return (
    <header className="w-full bg-white shadow-sm border-b-2 rounded-lg pb-4 flex flex-col gap-6 items-start justify-start">
      <div className="w-full px-6 flex flex-col gap-4">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold text-gray-800">{business.name}</h1>
          <BusinessSheetMobile business={business} />
        </div>
        <div className="flex flex-col items-start gap-2 justify-between">
          {/* TODO: Implementar tempo de entrega e entrega até x distância (de preferência já implementar feature para cobrar por entrega) */}
          <Badge
            className={cn(" text-black text-lg", {
              "bg-green-200 hover:bg-green-300": isBusinessOpenNow,
              "bg-red-500 hoverbg-red-600 text-white": !isBusinessOpenNow,
            })}
          >
            {isBusinessOpenNow ? "Aberto" : "Fechado"}
          </Badge>
        </div>
      </div>
      <div className="w-full relative px-6 h-11">
        <IconSearch
          size={14}
          className="absolute translate-x-3 translate-y-[15px]"
        />
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="flex w-full h-full items-start rounded-md pl-8 bg-gray-50 placeholder:text-sm"
          placeholder="Pesquise por nome de item ou categoria"
        />
      </div>
    </header>
  );
}
