import {
  IconArchive,
  IconHome,
  IconShoppingCart,
  IconToolsKitchen2,
  IconUserCircle,
} from "@tabler/icons-react";
import NavItem from "./nav.item";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useBusinessStore } from "@/pages/business/store/business.store";
import LeftNavBar from "../left-nav-bar/left-nav-bar";

export default function BottomNavBar() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const business = useBusinessStore((state) => state.business);

  const isBusinessRoutes = pathname.includes("/b");
  const isProfileRoutes = pathname.includes("/perfil");
  const isOrdersRoutes = pathname.includes("/pedidos");
  const isCartRoutes = pathname.includes("/carrinho");
  const isHomeRoutes = pathname === "/";

  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const amoutOfItemsOnCart = shoppingCart.items.length;

  // TODO: Melhorar a implementação das Orders (criar store e etc) para que um usuário possa ver seus pedidos pendentes na navbar
  return (
    <>
      <div className="sticky bottom-0 left-0 z-50 w-full p-4 bg-white border-t-2 border-gray-200 rounded-t-3xl">
        <div className="flex items-center justify-around gap-4">
          {business?.id ? (
            <NavItem
              href={`/b/${business.id}`}
              Icon={IconToolsKitchen2}
              label="Cardápio"
              isActive={isBusinessRoutes}
            />
          ) : (
            <NavItem
              href="/"
              Icon={IconHome}
              label="Início"
              isActive={isHomeRoutes}
            />
          )}
          <button
            type="button"
            onClick={() => navigate("/carrinho")}
            className="relative"
          >
            <NavItem
              Icon={IconShoppingCart}
              label="Carrinho"
              isActive={isCartRoutes}
            />
            {amoutOfItemsOnCart > 0 && (
              <div className="absolute top-[-14px] left-8 w-6 h-6 rounded-full bg-green-600 flex items-center justify-center">
                <span className="text-white text-[12px]">
                  {amoutOfItemsOnCart}
                </span>
              </div>
            )}
          </button>
          <NavItem
            href="/pedidos"
            Icon={IconArchive}
            label="Pedidos"
            isActive={isOrdersRoutes}
          />

          <button onClick={() => setOpen(true)}>
            <NavItem
              Icon={IconUserCircle}
              label="Perfil"
              isActive={isProfileRoutes}
            />
          </button>
        </div>
      </div>

      <LeftNavBar open={open} setOpen={setOpen} />
    </>
  );
}
