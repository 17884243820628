import { z } from "zod";

export const schema = z.object({
  description: z.string().optional(),
  paymentMethod: z.object({
    id: z.string(),
    name: z.string(),
    flag: z.string(),
  }),
  address: z.object({
    street: z.string(),
    number: z.coerce.number(),
    district: z.string(),
    city: z.string(),
    state: z.string(),
    postal_code: z.string(),
    info: z.string().optional(),
  }),
  changeAmount: z.number().optional(),
});

export type FormValues = z.infer<typeof schema>;
