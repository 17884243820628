import { useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import MenuItem from "./menu.item";
import { useBusinessStore } from "@/pages/business/store/business.store";
import NoImageFallback from "@/components/fallbacks/no-image.fallback";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import {
  MenuItem as MenuItemType,
  OptionalGroup,
  OptionalItem,
} from "@/types/menu.type";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import { cn } from "@/lib/utils";
import { v4 as uuidv4 } from "uuid";
import getS3Image from "@/utils/getS3Image.util";
import { useNavigate } from "react-router-dom";

type MenuItemSheetProps = {
  item: MenuItemType;
};

export default function MenuItemSheet({ item }: MenuItemSheetProps) {
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [selectedOptionals, setSelectedOptionals] = useState<OptionalItem[]>(
    []
  );

  const shoppingCart = useBusinessStore((state) => state.shoppingCart);

  const navigate = useNavigate();
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);

  const optionals: OptionalGroup[] = item.metadata?.optionals || [];

  const isOptionalSelected = (optional: OptionalItem) => {
    return selectedOptionals.some((selected) => selected.id === optional.id);
  };

  const isGroupFull = (group: OptionalGroup) => {
    const selectedInGroup = selectedOptionals.filter(
      (optional) => optional.groupId === group.id
    );
    return selectedInGroup.length >= group.max;
  };

  const areRequiredOptionalsSelected = () => {
    return optionals.every((group) => {
      if (!group.isRequired) return true;
      const selectedInGroup = selectedOptionals.filter(
        (optional) => optional.groupId === group.id
      );
      return selectedInGroup.length > 0;
    });
  };

  const calculateTotalPrice = () => {
    let total = item.price * selectedQuantity;
    selectedOptionals.forEach((optional) => {
      total += optional.price * selectedQuantity;
    });
    return total;
  };

  const resetSelectedOptionals = () => {
    setSelectedOptionals([]);
  };

  const toggleOptional = (group: OptionalGroup, optional: OptionalItem) => {
    setSelectedOptionals((prev) => {
      const isCurrentlySelected = prev.some(
        (selected) => selected.id === optional.id
      );
      if (isCurrentlySelected) {
        return prev.filter((selected) => selected.id !== optional.id);
      } else {
        if (isGroupFull(group)) {
          return prev;
        }
        return [...prev, { ...optional, groupId: group.id }];
      }
    });
  };

  const onAddToCart = () => {
    if (selectedQuantity === 0 || !areRequiredOptionalsSelected()) return;

    const newItem: ShoppingCartItem = {
      id: uuidv4(),
      itemId: item.id,
      price: item.price,
      title: item.title,
      description: item.description,
      quantity: selectedQuantity,
      optionals: selectedOptionals,
      logo_url: item.logo_url,
    };

    const existingItemIndex = shoppingCart.items.findIndex(
      (cartItem) =>
        cartItem.itemId === item.id &&
        JSON.stringify(cartItem.optionals?.sort()) ===
          JSON.stringify(selectedOptionals.sort())
    );

    if (existingItemIndex === -1) {
      setShoppingCart({
        ...shoppingCart,
        items: [...shoppingCart.items, newItem],
      });
    } else {
      const updatedItems = [...shoppingCart.items];
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        quantity: updatedItems[existingItemIndex].quantity + selectedQuantity,
      };

      setShoppingCart({ ...shoppingCart, items: updatedItems });
    }

    navigate("/carrinho");
    scrollTo(0, 0);
    setSelectedQuantity(0);
    resetSelectedOptionals();
  };

  const onIncrement = () => {
    setSelectedQuantity((prev) => prev + 1);
  };

  const onDecrement = () => {
    if (selectedQuantity > 0) {
      setSelectedQuantity((prev) => prev - 1);
    }
  };

  const isAddToCartDisabled =
    selectedQuantity === 0 || !areRequiredOptionalsSelected();

  const currentValueToAdd = calculateTotalPrice();

  return (
    <Sheet>
      <SheetTrigger className="h-full">
        <MenuItem item={item} />
      </SheetTrigger>
      <SheetContent className="sm:max-w-md w-full h-full flex flex-col gap-0 p-0 overflow-auto scroll-area">
        <div className="flex flex-col justify-between flex-1">
          <div className="flex flex-col">
            <div className="flex flex-col gap-4 flex-1 p-4 pt-0">
              {item.logo_url ? (
                <img
                  src={getS3Image(item.logo_url)}
                  alt={item.title}
                  className="min-h-60 max-h-60 rounded-lg object-cover"
                />
              ) : (
                <NoImageFallback className="min-h-60 max-h-60 rounded-lg" />
              )}
              <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <h1 className="font-semibold text-label-primary">
                    {item.title}
                  </h1>
                  {item.description && (
                    <p className="text-base text-label-secondary">
                      {item.description}
                    </p>
                  )}
                </div>
                <p className="font-semibold text-green-600">
                  {formatMoneyValue(item.price)}
                </p>
              </div>
            </div>
            {optionals.length > 0 && (
              <div>
                {optionals.map((group) => (
                  <div key={group.id}>
                    <div className="bg-gray-200 py-2 px-4 flex items-center justify-between gap-4">
                      <div className="w-full">
                        <h1 className="text-label-primary font-semibold">
                          {group.title}
                        </h1>
                        <p className="text-label-secondary text-base">
                          {
                            selectedOptionals.filter(
                              (o) => o.groupId === group.id
                            ).length
                          }{" "}
                          de {group.max} opções
                        </p>
                      </div>
                      {group.isRequired && (
                        <div>
                          <Badge>Obrigatório</Badge>
                        </div>
                      )}
                    </div>
                    {group.items.map((optional) => (
                      <button
                        type="button"
                        onClick={() => toggleOptional(group, optional)}
                        key={optional.id}
                        className="w-full flex items-center justify-between gap-2 p-4"
                      >
                        <div>
                          <h1
                            className={cn(
                              "font-semibold text-label-primary text-start",
                              {
                                "line-through font-normal text-label-secondary":
                                  isGroupFull(group) &&
                                  !isOptionalSelected(optional),
                              }
                            )}
                          >
                            {optional.title}
                          </h1>
                        </div>
                        <Checkbox
                          className="w-6 h-6"
                          checked={isOptionalSelected(optional)}
                          disabled={
                            isGroupFull(group) && !isOptionalSelected(optional)
                          }
                        />
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="p-4">
            <div className="w-full rounded-lg p-4 bg-gray-100 border flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Button
                  onClick={onDecrement}
                  className="w-8 h-8 rounded-full text-lg font-semibold bg-black hover:bg-black"
                >
                  -
                </Button>
                <span className="font-semibold text-lg">
                  {selectedQuantity}
                </span>
                <Button
                  onClick={onIncrement}
                  className="w-8 h-8 rounded-full text-lg font-semibold bg-black hover:bg-black"
                >
                  +
                </Button>
              </div>
              <Button
                disabled={isAddToCartDisabled}
                onClick={onAddToCart}
                className="bg-green-600 hover:bg-green-700"
              >
                Adicionar {formatMoneyValue(currentValueToAdd)}
              </Button>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
