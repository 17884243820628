import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignIn from "./sign-in";
import SignUp from "./sign-up";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useAuth } from "@/context/auth.context";
import { useBusinessStore } from "@/pages/business/store/business.store";

type AuthenticationDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function AuthenticationDialog({
  open,
  setOpen,
}: AuthenticationDialogProps) {
  const [tab, setTab] = useState("sign-in");

  const { user } = useAuth();
  const navigate = useNavigate();
  const business = useBusinessStore((state) => state.business);

  const userId = user?.id;

  function onAuthDialogClode() {
    if (userId) return;
    setOpen(false);
    if (business?.id) {
      navigate(`/b/${business.id}`);
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    if (userId) setOpen(false);
  }, [userId, setOpen]);

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);

        if (!value) onAuthDialogClode();
      }}
    >
      <DialogContent className="p-0 w-full flex items-center justify-center">
        <Tabs
          value={tab}
          onValueChange={(value) => setTab(value)}
          defaultValue="sign-up"
          className=" flex flex-col gap-4 px-4 pt-12 pb-10 items-center justify-start w-full h-full"
        >
          <div className="max-w-[400px] h-full mx-auto flex w-full flex-col items-center justify-center gap-4">
            <div className="w-full flex flex-col items-center justify-center">
              {tab === "sign-in" && (
                <TabsContent
                  value="sign-in"
                  className="w-full flex items-center justify-center"
                >
                  <SignIn setTab={setTab} />
                </TabsContent>
              )}
              {tab === "sign-up" && (
                <TabsContent
                  className="w-full flex items-center justify-center"
                  value="sign-up"
                >
                  <SignUp setTab={setTab} />
                </TabsContent>
              )}
            </div>

            <p className="px-8 text-center text-sm text-label-secondary">
              Ao clicar em continuar, você concorda com nossos{" "}
              <Link
                to="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Termos de serviço
              </Link>{" "}
              e{" "}
              <Link
                to="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Política de Privacidade
              </Link>
              .
            </p>
          </div>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
