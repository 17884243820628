import { Label } from "@/components/ui/label";

export default function FieldContainer({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) {
  return (
    <div className="flex flex-col space-y-1.5">
      <div className="w-full justify-between items-center flex">
        <Label htmlFor="identifier">{label}</Label>
      </div>
      {children}
    </div>
  );
}
