import { Order } from "@/types/order.type";
import OrderItem from "@/pages/orders/components/order-item";
import NoHistoryItemFallback from "./fallbacks/no-history-item.fallback";

export default function OrderTab({
  orders,
  onClickDetails,
  onClickOrderAgain,
}: {
  orders: Order[];
  onClickDetails: (order: Order) => void;
  onClickOrderAgain?: (order: Order) => void;
}) {
  return (
    <div className="grid gap-4">
      {orders.map((order) => (
        <OrderItem
          order={order}
          onClickDetails={onClickDetails}
          onClickOrderAgain={onClickOrderAgain}
        />
      ))}

      {orders.length === 0 && <NoHistoryItemFallback />}
    </div>
  );
}
