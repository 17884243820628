import { Sheet, SheetContent } from "@/components/ui/sheet";
import Addresses from "@/pages/address/components/addresses";
import { Dispatch, SetStateAction } from "react";

type SelectAddressSheetProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export function SelectAddressSheet({ open, setOpen }: SelectAddressSheetProps) {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="p-0 max-w-[unset] w-full">
        <div className="px-4">
          <Addresses />
        </div>
      </SheetContent>
    </Sheet>
  );
}
