import LoadingBusinessFallback from "@/pages/business/components/fallbacks/loading-business.fallback";
import NotfoundBusiness from "@/pages/business/components/fallbacks/notfound.business";
import useBusiness from "@/pages/business/hook/business.hook";
import NotFoundMenuCategoryFallback from "@/pages/business/components/fallbacks/notfound-menuCategory.fallback";
import MenuItemSheet from "@/pages/business/components/menu/menu.item.sheet";
import BusinessHeader from "@/pages/business/components/business.header";

export default function BusinessPage() {
  const {
    isLoading,
    business,
    search,
    setSearch,
    isCategoriesEmpty,
    filteredMenuCategories,
  } = useBusiness();

  if (isLoading) return <LoadingBusinessFallback />;
  if (!business) return <NotfoundBusiness />;

  return (
    <div className="w-full flex-1 flex items-start justify-center">
      <section className="w-full h-full py-6 flex-col gap-4 flex flex-1">
        <div className="w-full flex-col flex items-center justify-start flex-1">
          <BusinessHeader
            business={business}
            search={search}
            setSearch={setSearch}
          />
          <div className="w-full flex flex-col gap-6 pt-6">
            <div className="w-full flex-col  gap-6 px-6 flex items-center justify-start flex-1">
              {isCategoriesEmpty && <NotFoundMenuCategoryFallback />}
              {filteredMenuCategories.map((category) => (
                <div key={category.id} className="w-full flex flex-col gap-4">
                  <h1 className="text-2xl font-semibold">{category.title}</h1>
                  <div className="flex flex-col gap-4">
                    {category.menu_items?.map((item) => (
                      <MenuItemSheet key={item.id} item={item} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
