import { Card, CardContent, CardFooter } from "@/components/ui/card";

import { AuthForm } from "../Form";
import { useSignUpForm } from "../auth.actions";
import { IconPlayerPlayFilled } from "@tabler/icons-react";
import { Input } from "@/components/ui/input";
import ReactPhoneInput from "react-phone-input-2";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth.context";
import Spinner from "@/components/ui/spinner";

export default function SignUp({
  setTab,
}: {
  setTab: (value: string) => void;
}) {
  const { form, handleSignUp } = useSignUpForm();

  const { loading } = useAuth();

  const {
    formState: { errors },
  } = form;

  function handleGoToSignIn() {
    setTab("sign-in");
  }

  return (
    <Card
      className="w-full border-none"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(25, 28, 33, 0.2) 0px 15px 35px -5px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px",
      }}
    >
      <AuthForm.Header
        title={"Cadastrar em Pedido.io"}
        description={"Crie sua conta para começar"}
      />
      <CardContent className="px-6 py-4">
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(handleSignUp)}
        >
          <div className="grid w-full items-center gap-4">
            <AuthForm.FieldContainer label="Nome">
              <Input
                {...form.register("name")}
                placeholder="Seu nome"
                type="text"
                className="h-8"
              />
              {errors["name"] && (
                <p className="text-red-500 text-xs">{errors["name"].message}</p>
              )}
            </AuthForm.FieldContainer>
            <AuthForm.FieldContainer label="Telefone">
              <ReactPhoneInput
                {...form.register("phone_number")}
                value={form.watch("phone_number")}
                country={"br"}
                inputStyle={{
                  width: "100%",
                  height: "32px",
                  border: "1px solid #e5e7eb",
                  borderRadius: "8px",
                }}
                copyNumbersOnly
                countryCodeEditable={false}
                onlyCountries={["br"]}
                onChange={(value) => {
                  form.setValue("phone_number", value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
              {errors["phone_number"] && (
                <p className="text-red-500 text-xs">
                  {errors["phone_number"].message}
                </p>
              )}
            </AuthForm.FieldContainer>
          </div>
          <CardFooter className="flex flex-col gap-4 p-0">
            <Button
              type="submit"
              className="w-full h-8 bg-gray-900 hover:bg-black flex items-center justify-center gap-2"
            >
              <p>Cadastrar</p>
              {loading ? <Spinner /> : <IconPlayerPlayFilled size={10} />}
            </Button>
            <p
              onClick={handleGoToSignIn}
              className="text-center text-sm text-label-primary"
            >
              Já tem uma conta?{" "}
              <span className="font-bold cursor-pointer">Entrar</span>
            </p>
          </CardFooter>
        </form>
      </CardContent>
    </Card>
  );
}
