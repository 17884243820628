/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { Textarea } from "@/components/ui/textarea";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "@/pages/profile/store/user.store";
import { toast } from "sonner";
import isBusinessInOperationTime from "@/utils/isBusinessInOperationTime.util";
import AuthenticationDialog from "@/components/authentication/authentication.dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import { IconMapPin } from "@tabler/icons-react";
import { SelectAddressSheet } from "@/components/selectAddress.sheet";
import NotFoundCartItemsFallback from "../components/fallbacks/notfound-cartItems.fallback";
import { postOrder } from "../queries/postOrder";
import CartItem from "../components/cart-item";
import { MoneyInput } from "@/components/inputs/money.input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { cn } from "@/lib/utils";
import { ShoppingCart } from "@/types/shopping-cart.type";
import { UserAddress } from "@/types/user.type";
import { FormValues, schema } from "../schemas/cart.schemas";
import BadgeError from "../components/badge-error";

export default function CartPage() {
  const navigate = useNavigate();
  const [selectOpen, setSelectOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);

  const user = useUserStore((state) => state.user);
  const business = useBusinessStore((state) => state.business);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);

  const paymentMethods = business?.metadata?.paymentMethods || [];
  const userAddresses = user?.addresses || [];
  const userMainAddress = userAddresses.find((address) => address.is_selected);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const selectedPaymentMethod = watch("paymentMethod");

  useEffect(() => {
    setValue("address", userMainAddress as UserAddress);

    trigger();
  }, [userMainAddress, setValue, trigger]);

  if (shoppingCart.items.length === 0) return <NotFoundCartItemsFallback />;

  const onSubmit = async (data: FormValues) => {
    if (!business || !user) return;

    if (shoppingCart.items.length === 0) {
      return;
    }

    try {
      setIsSubmitting(true);

      const order = await postOrder({
        business_id: business.id,
        description: data.description,
        user_id: user.id,
        items: shoppingCart.items,
        payment_method: data.paymentMethod,
        user,
        address: data.address,
        business,
        change_amount: data.changeAmount,
      });

      toast.success("Pedido efetuado com sucesso!");
      navigate("/pedidos");

      if (order) {
        setShoppingCart({ ...shoppingCart, items: [] });
      }
    } catch (error: any) {
      console.error("Erro ao finalizar o pedido:", error);

      if (error.response?.data?.message?.includes("Business is closed")) {
        toast.error("O estabelecimento está fechado no momento.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateTotal = () =>
    shoppingCart.items.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );

  const isFinalizeButtonDisabled = () => {
    if (!business) return true;
    if (!isBusinessInOperationTime(business)) return true;
    if (shoppingCart.items.length === 0) return true;
    if (isSubmitting) return true;
    return false;
  };

  function handleSelectAddress() {
    if (!user) {
      return setAuthOpen(true);
    }
    setSelectAddressOpen(true);
  }

  const handlePaymentMethodChange = (value: string) => {
    const method = paymentMethods.find((m) => m.id === value);

    if (!method) return;

    setValue("paymentMethod", method, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    if (method?.flag === "Dinheiro") {
      setChangeDialogOpen(true);
    } else {
      setValue("changeAmount", undefined, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  const handleChangeAmount = (value: number) => {
    setValue("changeAmount", value);
  };

  return (
    <div className="w-full flex-1 flex items-start justify-center">
      <section className="w-full h-full py-6 px-6 flex-col gap-4 flex">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex-col gap-8 flex items-start justify-center"
        >
          <ItemsOnCart shoppingCart={shoppingCart} />
          <DeliveryAddress
            control={control}
            errors={errors}
            handleSelectAddress={handleSelectAddress}
          />
          <OrderNotes control={control} />
          <PaymentMethod
            control={control}
            errors={errors}
            paymentMethods={paymentMethods}
            setSelectOpen={setSelectOpen}
            selectOpen={selectOpen}
            handlePaymentMethodChange={handlePaymentMethodChange}
          />
          <ExchangeAmount
            selectedPaymentMethod={selectedPaymentMethod}
            control={control}
            errors={errors}
            handleChangeAmount={handleChangeAmount}
          />
          <OrderSummary calculateTotal={calculateTotal} />
          <FinalizeOrder isFinalizeButtonDisabled={isFinalizeButtonDisabled} />
        </form>

        <AuthenticationDialog open={authOpen} setOpen={setAuthOpen} />
        <SelectAddressSheet
          open={selectAddressOpen}
          setOpen={setSelectAddressOpen}
        />

        <Dialog open={changeDialogOpen} onOpenChange={setChangeDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Troco necessário</DialogTitle>
              <DialogDescription>
                Você selecionou pagamento em dinheiro. Por favor, informe o
                valor para troco:
              </DialogDescription>
            </DialogHeader>
            <Controller
              name="changeAmount"
              control={control}
              render={({ field }) => (
                <MoneyInput
                  value={field.value || 0}
                  onValueChange={handleChangeAmount}
                  className={errors.changeAmount ? "border-red-500" : ""}
                />
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  variant="outline"
                  onClick={() => setChangeDialogOpen(false)}
                >
                  Cancelar
                </Button>
              </DialogClose>
              <Button onClick={() => setChangeDialogOpen(false)}>
                Confirmar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </section>
    </div>
  );
}

function ItemsOnCart({ shoppingCart }: { shoppingCart: ShoppingCart }) {
  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold text-label-primary">
        Itens no carrinho
      </h1>
      <div className="w-full flex flex-col items-start justify-center gap-4">
        {shoppingCart.items.map((item) => (
          <CartItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

function DeliveryAddress({
  errors,
  control,
  handleSelectAddress,
}: {
  errors: any;
  control: any;
  handleSelectAddress: () => void;
}) {
  return (
    <div className={cn("w-full flex flex-col gap-4")}>
      <h1 className="text-lg font-semibold text-label-primary flex items-center gap-2">
        Endereço de entrega {errors.address && <BadgeError />}
      </h1>
      <div
        className={cn(
          "w-full flex flex-col items-start justify-center gap-4 flex-wrap",
          {
            "border-red-500 border p-4 rounded-lg": errors.address,
          }
        )}
      >
        <div className="w-full flex items-center justify-center gap-2">
          <IconMapPin size={24} />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <div
                className={`flex items-center justify-between w-full flex-wrap ${
                  errors.address ? "border-red-500" : ""
                }`}
              >
                {field.value ? (
                  <>
                    <div className="flex items-start justify-start flex-col">
                      <p className="text-label-primary font-semibold">
                        {field.value.street}, {field.value.number}
                      </p>
                      <p className="text-label-secondary">
                        {field.value.district}, {field.value.city} -{" "}
                        {field.value.state}
                      </p>
                    </div>
                    <Button
                      type="button"
                      onClick={handleSelectAddress}
                      className="bg-transparent hover:bg-transparent p-0 text-label-primary text-base underline"
                    >
                      Trocar endereço
                    </Button>
                  </>
                ) : (
                  <div className="w-full flex items-center justify-start">
                    <Button
                      type="button"
                      onClick={handleSelectAddress}
                      className="bg-transparent hover:bg-transparent p-0 text-label-primary text-base underline"
                    >
                      Selecionar endereço
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}

function OrderNotes({ control }: { control: any }) {
  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold text-label-primary">
        Observações do pedido
      </h1>
      <div className="w-full flex flex-col items-start justify-center gap-4">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea {...field} placeholder="Observações do pedido" />
          )}
        />
      </div>
    </div>
  );
}

function PaymentMethod({
  errors,
  control,
  paymentMethods,
  setSelectOpen,
  selectOpen,
  handlePaymentMethodChange,
}: {
  errors: any;
  control: any;
  paymentMethods: any[];
  setSelectOpen: any;
  selectOpen: boolean;
  handlePaymentMethodChange: any;
}) {
  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold text-label-primary flex items-center gap-2">
        Método de pagamento {errors.paymentMethod && <BadgeError />}
      </h1>
      <div className="w-full flex flex-col items-start justify-center gap-4">
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field }) => (
            <Select
              open={selectOpen}
              onOpenChange={setSelectOpen}
              value={field.value?.id}
              onValueChange={handlePaymentMethodChange}
            >
              <SelectTrigger
                className={`w-full ${
                  errors.paymentMethod ? "border-red-500" : ""
                }`}
                onClick={() => setSelectOpen(true)}
              >
                <SelectValue placeholder="Selecionar método de pagamento" />
              </SelectTrigger>
              <SelectContent>
                {paymentMethods.map((payment) => (
                  <SelectItem key={payment.id} value={payment.id}>
                    <div className="flex items-center justify-center gap-2">
                      <div className="w-5 h-5 flex items-center justify-center">
                        <img
                          src={
                            PAYMENT_FLAGS[
                              payment.flag as keyof typeof PAYMENT_FLAGS
                            ].image
                          }
                          alt={payment.name}
                          className="w-5 h-5"
                        />
                      </div>
                      <p>{payment.name}</p>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </div>
    </div>
  );
}

function ExchangeAmount({
  selectedPaymentMethod,
  control,
  errors,
  handleChangeAmount,
}: {
  selectedPaymentMethod: any;
  control: any;
  errors: any;
  handleChangeAmount: any;
}) {
  if (selectedPaymentMethod?.flag !== "Dinheiro") return null;

  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold text-label-primary">
        Valor do troco em dinheiro
      </h1>
      <div className="w-full flex flex-col items-start justify-center gap-4">
        <Controller
          name="changeAmount"
          control={control}
          render={({ field }) => (
            <MoneyInput
              value={field.value}
              onValueChange={handleChangeAmount}
              className={errors.changeAmount ? "border-red-500" : ""}
            />
          )}
        />
      </div>
    </div>
  );
}

function OrderSummary({ calculateTotal }: { calculateTotal: () => number }) {
  return (
    <div className="w-full flex flex-col gap-6">
      <h1 className="text-lg font-semibold text-label-primary">
        Resumo do pedido
      </h1>
      <div className="w-full border rounded-md p-4 flex flex-col gap-4 items-start justify-center">
        <div className="w-full flex-col flex items-center justify-between gap-2">
          <div className="w-full flex items-center justify-between gap-4">
            <p className="text-label-secondary">Subtotal</p>
            <p className="text-label-primary font-semibold">
              R$ {calculateTotal()}
            </p>
          </div>
        </div>
        <div className="w-full flex-col flex items-center justify-between gap-2">
          <div className="w-full flex items-center justify-between gap-4">
            <p className="text-label-secondary">Taxa de entrega</p>
            <p className="font-semibold text-green-600">Grátis</p>
          </div>
        </div>
        <Separator />
        <div className="w-full flex items-center justify-between gap-4">
          <p className="text-label-primary text-lg font-semibold">Total</p>
          <p className="font-semibold">R$ {calculateTotal()}</p>
        </div>
      </div>
    </div>
  );
}

function FinalizeOrder({
  isFinalizeButtonDisabled,
}: {
  isFinalizeButtonDisabled: () => boolean;
}) {
  return (
    <div className="w-full flex items-center justify-end">
      <Button
        type="submit"
        disabled={isFinalizeButtonDisabled()}
        className="bg-black text-white hover:bg-black"
      >
        Finalizar pedido
      </Button>
    </div>
  );
}
