import NoImageFallback from "@/components/fallbacks/no-image.fallback";
import { MenuItem as TypeMenuItem } from "@/types/menu.type";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import getS3Image from "@/utils/getS3Image.util";

type MenuItemProps = {
  item: TypeMenuItem;
};

export default function MenuItem({ item }: MenuItemProps) {
  return (
    <button
      type="button"
      className="w-full rounded-md border px-4 cursor-pointer h-full py-4 bg-white"
    >
      <div className="w-full flex items-center justify-between gap-4">
        <div className="flex justify-between items-start text-start gap-6">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <h1 className="font-medium text-label-primary text-base sm:text-base">
                {item.title}
              </h1>
              {item.description && (
                <p className="text-base text-label-secondary">
                  {item.description}
                </p>
              )}
            </div>
            <p className="font-semibold text-green-600">
              {formatMoneyValue(item.price)}
            </p>
          </div>
        </div>

        {item.logo_url ? (
          <img
            src={getS3Image(item.logo_url)}
            alt={item.title}
            className="min-w-24 min-h-24 max-w-24 max-h-24 rounded-lg object-cover"
          />
        ) : (
          <NoImageFallback className="min-w-24 min-h-24 max-w-24 max-h-24 rounded-lg " />
        )}
      </div>
    </button>
  );
}
